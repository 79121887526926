import kinder from '../../assets/images/kinder_mobile.jpg';
import p4h from '../../assets/images/p4h_mobile.jpg';
import openimis from '../../assets/images/openimis_mobile.jpg';
import owl from '../../assets/images/owl.svg';
import thermeon from '../../assets/images/thermeon.jpg';
import minimax from '../../assets/images/minimax.jpg';
import artound from '../../assets/images/artound.jpg';
import successWorks from '../../assets/images/success_works.png';
import kompasbg from '../../assets/images/kompasbg.jpeg';
import sofiahistorymuseum from '../../assets/images/sofiahistorymuseum.jpg';
import stolbg from '../../assets/images/stolbg.jpg';
import tuningWorld from '../../assets/images/tuning_world.png';
import velivoturnovoinfo from '../../assets/images/velikoturnovoinfo.jpg';
import nikai from '../../assets/images/nikai.jpg';
import totbooks from '../../assets/images/totbooks.jpg';
const projects = [
    {
        id: 0,
        title: 'Kinder official website',
        desc: 'Ferrero Kinder brand line of chocolate products.',
        img: kinder,
        link: 'https://www.kinder.com',
        tags: ['Drupal', 'HTML', 'SCSS', 'Javascript']
    },
    {
        id: 1,
        title: 'P4H official website',
        desc: 'The global network for Universal Health Coverage.',
        img: p4h,
        link: 'https://p4h.world',
        tags: ['Drupal', 'HTML', 'SCSS', 'Javascript']
    },
    {
        id: 2,
        title: 'openIMIS official website',
        desc: 'openIMIS is a data-driven, open-source solution.',
        img: openimis,
        link: 'https://openimis.org',
        tags: ['Drupal', 'HTML', 'SCSS', 'Javascript']
    },
    {
        id: 3,
        title: 'Artound ',
        desc: 'Social network for artists and fans in Italy',
        img: artound,
        link: 'https://app.artound.com/',
        tags: ['WordPress', 'CSS', 'HTML', 'Javascript', 'PHP']
    },
    {
        id: 4,
        title: 'Success works',
        desc: 'Unique initiative designed to secure better employment opportunities for vulnerable women in our community. ',
        img: successWorks,
        link: 'https://successworks.org.au',
        tags: ['WordPress', 'CSS', 'HTML', 'Javascript', 'PHP']
    },
    {
        id: 5,
        title: 'Kompasbg',
        desc: 'Kompasbg for breaking news, video, entertainment, business, science, technology and health news.',
        img: kompasbg,
        link: 'https://kompasbg.com',
        tags: ['WordPress', 'CSS', 'HTML', 'Javascript', 'PHP']
    },
    {
        id: 6,
        title: 'Sofia Regional History Museum',
        desc: 'Educational programs, expositions, events, tickets, regional activities.',
        img: sofiahistorymuseum,
        link: 'https://www.sofiahistorymuseum.bg',
        tags: ['Joomla', 'CSS', 'HTML', 'Javascript', 'PHP']
    },
    {
        id: 7,
        title: 'STOL Bulgaria',
        desc: 'The importer of original luxury Italian furniture, kitchens, lighting and accessories for a home, office or hotel.',
        img: stolbg,
        link: 'https://stolbg.com',
        tags: ['WordPress', 'CSS', 'HTML', 'Javascript', 'PHP']
    },
    {
        id: 8,
        title: 'Tuning World',
        desc: 'Delivery of only quality tuning auto parts and accessories of world brands.',
        img: tuningWorld,
        link: 'https://tuning-world.bg',
        tags: ['WordPress', 'CSS', 'HTML', 'Javascript', 'PHP']
    },
    {
        id: 9,
        title: 'Veliko Tarnovo Info',
        desc: 'About Veliko Tarnovo - news, accommodation, restaurants, sights, recreation.',
        img: velivoturnovoinfo,
        link: 'https://velikoturnovo.info',
        tags: ['WordPress', 'CSS', 'HTML', 'Javascript', 'PHP']
    },
    {
        id: 10,
        title: 'Ni Kai Bakeries',
        desc: 'Production of bakes, cakes, pies, bread and snaks.',
        img: nikai,
        link: 'https://ni-kai.com/',
        tags: ['WordPress', 'CSS', 'HTML', 'Javascript', 'PHP']
    },
    {
        id: 11,
        title: 'Tot Publishing House',
        desc: 'The bookstore of publishing house.',
        img: totbooks,
        link: 'https://totbooksbg.com/',
        tags: ['WordPress', 'CSS', 'HTML', 'Javascript', 'PHP']
    },
    {
        id: 12,
        title: 'OWLtop',
        desc: 'Educational courses',
        img: owl,
        link: 'https://alexasky.github.io/top-app',
        github: 'https://github.com/Alexasky/top-app',
        tags: ['NextJS', 'CSS', 'API', 'TypeScript', 'Javascript']
    },
    {
        id: 13,
        title: 'Thermeon ',
        desc: 'Car Rental Software and Management Solutions',
        img: thermeon,
        link: 'https://www.thermeon.com/',
        tags: ['WordPress', 'CSS', 'HTML', 'Javascript', 'PHP']
    },
    {
        id: 14,
        title: 'Minimax ',
        desc: 'Specialists in fast-build houses',
        img: minimax,
        link: 'https://housebetongroup.ru/',
        tags: ['WordPress', 'CSS', 'HTML', 'Javascript', 'PHP']
    },
];

export default projects;
